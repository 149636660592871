import React, { useEffect } from 'react';
import { Grid, Typography, Box, Paper, InputBase, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import GmisLogo from '../components/gmis-logo';
import FullPageLayout from '../components/fullpage-layout';
import { Link, navigate } from 'gatsby';

export default function NotFound() {
  useEffect(() => {
    navigate('/');
    // eslint-disable-next-line
  }, []);
  return (
    <FullPageLayout>
      <Box clone p={4} width={1} justifyContent={{ xs: 'center', sm: 'flex-start' }}>
        <Grid container>
          <Grid item>
            <Link to='/'>
              <GmisLogo viewBox='0 0 238 50' />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3} direction='column' alignItems='center' justify='center'>
        <Grid item xs={12}>
          <Typography variant='h4' color='textSecondary' gutterBottom>
            Page Not Found!
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Typography variant='subtitle1' color='textSecondary' gutterBottom>
            The page you are looking for might have been removed, had its name changed or is temporarily unavailable.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={0} xs={12}>
            <InputBase placeholder='Search Here' />
            <Button variant='contained' color='primary'>
              <SearchIcon />
              Search
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box my={5}>
            <Grid container justify='center' spacing={3}>
              <Grid item>
                <Link to='/summits/2022' className='text-decoration-none'>
                  <Typography color='primary' variant='h6'>
                    #GMIS2022
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to='/summits/2021' className='text-decoration-none'>
                  <Typography color='primary' variant='h6'>
                    #GMIS2021
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to='/summits/2020' className='text-decoration-none'>
                  <Typography color='primary' variant='h6'>
                    #GMIS2020
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to='/summits/2019' className='text-decoration-none'>
                  <Typography color='primary' variant='h6'>
                    #GMIS2019
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link to='/summits/2017' className='text-decoration-none'>
                  <Typography color='primary' variant='h6'>
                    #GMIS2017
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Link to='/' className='text-decoration-none'>
            <Box display='flex' alignItems='center'>
              <ChevronLeftIcon color='primary' />
              <Typography variant='button' display='inline' color='primary'>
                Go to homepage
              </Typography>
            </Box>
          </Link>
        </Grid>
      </Grid>
    </FullPageLayout>
  );
}

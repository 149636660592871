import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import { Container, CssBaseline, Box } from '@material-ui/core';
import colors from '../constants/colors';

export default function FullPageLayout({ prpos, children }) {
  const [darkTheme] = useState(true);
  const theme = React.useMemo(() => {
    let _theme = createMuiTheme({
      palette: {
        type: darkTheme ? 'dark' : 'light',
        background: {
          default: darkTheme ? colors.black : colors.white,
          dark: colors.primary.dark,
          light: colors.white,
        },
        primary: {
          main: darkTheme ? colors.primary.dark : colors.primary.light,
          dark: colors.primary.dark,
          light: colors.primary.light,
        },
        secondary: {
          main: darkTheme ? colors.secondary.dark : colors.secondary.light,
          dark: colors.secondary.dark,
          light: colors.secondary.light,
        },
        error: {
          main: darkTheme ? colors.error.dark : colors.error.light,
          dark: colors.error.dark,
          light: colors.error.light,
        },
        warning: {
          main: darkTheme ? colors.warning.dark : colors.warning.light,
          dark: colors.warning.dark,
          light: colors.warning.light,
        },
        info: {
          main: darkTheme ? colors.info.dark : colors.info.light,
          dark: colors.info.dark,
          light: colors.info.light,
        },
        success: {
          main: darkTheme ? colors.success.dark : colors.success.light,
          dark: colors.success.dark,
          light: colors.success.light,
        },
        text: {
          primary: darkTheme ? colors.text.primary.dark : colors.text.primary.light,
          secondary: darkTheme ? colors.text.secondary.dark : colors.text.secondary.light,
          disabled: darkTheme ? colors.text.disabled.dark : colors.text.disabled.light,
        },
      },
      typography: {
        fontSize: 16,
        h1: {
          fontWeight: 400,
          fontSize: '3rem',
        },
      },
      overrides: {
        MuiInput: {
          underline: {
            '&:before': {
              borderBottom: 0,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 0,
            },
          },
        },
        MuiFormHelperText: {
          root: {
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
        MuiInputBase: {
          root: {
            borderRadius: '5px',
            backgroundColor: darkTheme
              ? `${colors.white}${colors.transparency.input.secondary}`
              : `${colors.black}${colors.transparency.input.primary}`,
            '&:hover': {
              backgroundColor: darkTheme
                ? `${colors.white}${colors.transparency.input.primary}`
                : `${colors.black}${colors.transparency.input.secondary}`,
            },
          },
          input: {
            padding: '8px 8px 12px 8px',
          },
        },
        MuiSelect: {
          icon: {
            fill: colors.transparentColor.primary,
          },
        },
        MuiInputLabel: {
          formControl: {
            left: '8px',
            right: '8px',
          },
        },
        MuiStepper: {
          root: {
            padding: '0px',
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },

        MuiStep: {
          horizontal: {
            paddingLeft: '0px',
            paddingRight: '0px',
          },
        },
        MuiStepConnector: {
          active: {
            '& .MuiStepConnector-line': {
              borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            },
          },
          completed: {
            '& .MuiStepConnector-line': {
              borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            },
          },
        },
        MuiAppBar: {
          colorDefault: {
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiButton: {
          containedPrimary: {
            color: darkTheme ? colors.primary.dark : colors.primary.light,
            border: '1px solid',
            borderColor: darkTheme ? colors.primary.dark : colors.primary.light,
            backgroundColor: darkTheme
              ? `${colors.primary.dark}${colors.transparency.button}`
              : `${colors.primary.light}${colors.transparency.button}`,
            '&:hover': {
              color: colors.white,
              backgroundColor: darkTheme ? `${colors.primary.dark}` : `${colors.primary.light}`,
            },
          },
        },
        MuiCardContent: {
          root: {
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiPaper: {
          root: {
            backgroundColor: darkTheme ? colors.blue : colors.white,
          },
        },
        MuiAccordionSummary: {
          color: colors.text.secondary,
          expandIcon: {
            color: colors.text.secondary,
            fontSize: '40px',
            '&$expanded': {
              transform: 'rotate(90deg)',
            },
          },
        },
      },
    });
    return responsiveFontSizes(_theme);
  }, [darkTheme]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Box className={`honeycomb ${darkTheme ? '' : 'light'}`}>
          <Container maxWidth='xl'>{children}</Container>
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
}
